import React from 'react'

import Navbar from '../navigation/navbar'
import Footer from '../navigation/footer'
import Hero1 from '../hero/hero1'

/**
 * One col layout, with shrinked column size
 */
export default function SingleLayout10(props) {
  return (
    <>
    <Navbar />
    
    {props.hero && <Hero1 title={props.hero.title} img={props.hero.image}/>}

    <main id="content" role="main">
      <div className="container space-top-2 space-bottom-2">
        {props.children}
      </div>
    </main>

    
    <Footer />
    </>
  )
}
