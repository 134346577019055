import React, { Component } from 'react'
import { Link } from "gatsby";
import { FaLinkedinIn, FaFacebookF, FaGithub,
  FaTwitter } from 'react-icons/fa';

export default class Footer extends Component {
  render() {
    return (
      <footer className="bg-navy">
        <div className="container space-2">
          <div className="w-lg-80 mx-lg-auto">
          <div className="row mb-9">
            <div className="col-md-3 mb-5 mb-md-0">
              <Link to="/">
                <img className="brand" src="/logo/logo.png" alt="Logo" />
              </Link>
            </div>
      
            <div className="col-sm-4 col-md-3 mb-3 mb-sm-0">
              <h5 className="text-white font-weight-bold">Posts</h5>
              <ul className="nav nav-sm nav-x-0 nav-white flex-column">
                <li className="nav-item"><Link className="nav-link" to="/posts/">Browse Posts</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/coding-interview/">Coding Interview Guide</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/tags/">Tags and Categories</Link></li>
              </ul>
            </div>
      
            <div className="col-sm-4 col-md-3 mb-3 mb-sm-0">
              <h5 className="text-white font-weight-bold">About us</h5>
              <ul className="nav nav-sm nav-x-0 nav-white flex-column">
                <li className="nav-item"><Link className="nav-link" to="/about/">About</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/contact/">Contact Us</Link></li>
                {/* TODO <li className="nav-item"><Link className="nav-link" to="/portfolio/">Author Portfolio</Link></li> */}
              </ul>
            </div>
      
            <div className="col-sm-4 col-md-3 mb-3 mb-sm-0">
              <h5 className="text-white font-weight-bold">Terms & Conditions</h5>
              <ul className="nav nav-sm nav-x-0 nav-white flex-column">
                <li className="nav-item"><Link className="nav-link" to="/terms/">Terms</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/privacy-policy/">Privacy</Link></li>
              </ul>
            </div>
            
          </div>
      
          <hr className="opacity-xs my-0" />

          <div className="space-1">
      <div className="row align-items-md-center mb-7">
        <div className="col-md-6 mb-4 mb-md-0">
          <ul className="nav nav-sm nav-white nav-x-sm align-items-center">
            <li className="nav-item">
              <Link className="nav-link" to="/privacy-policy/">Privacy & Policy</Link>
            </li>
            <li className="nav-item opacity mx-3">/</li>
            <li className="nav-item">
              <Link className="nav-link" to="/terms/">Terms</Link>
            </li>
            <li className="nav-item opacity mx-3">/</li>
            <li className="nav-item">
              <Link className="nav-link" to="/posts/">Site Map</Link>
            </li>
          </ul>
        </div>

        <div className="col-md-6 text-md-right">
          <ul className="list-inline mb-0">
            <li className="list-inline-item">
              <a className="btn btn-xs btn-icon btn-soft-light" href="https://www.facebook.com/gyanblog" target="_blank" rel="noreferrer">
                <FaFacebookF />
              </a>
            </li>
            <li className="list-inline-item">
              <Link className="btn btn-xs btn-icon btn-soft-light" to="#" target="_blank" rel="noreferrer">
                <FaLinkedinIn />
              </Link>
            </li>
            <li className="list-inline-item">
              <Link className="btn btn-xs btn-icon btn-soft-light" to="#" target="_blank" rel="noreferrer">
                <FaTwitter />
              </Link>
            </li>
            <li className="list-inline-item">
              <a className="btn btn-xs btn-icon btn-soft-light" href="https://github.com/GyanBlog" target="_blank" rel="noreferrer">
                <FaGithub />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="w-md-75 text-lg-center mx-lg-auto">
        <p className="text-white opacity-sm small">© GyanBlog. 2020. All rights reserved.</p>
        <p className="text-white opacity-sm small">When you visit or interact with our sites, services or tools, we or our authorised service providers may use cookies for storing information to help provide you with a better, faster and safer experience and for marketing purposes.</p>
      </div>
    </div>
          </div>
        </div>
      </footer>
    )
  }
}
